import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { PhoneMaskDirective } from './directives/phonemask/phone-mask.directive';
import { RefillPrescriptionsComponent } from "./refill-prescriptions/refill-prescriptions.component";
import { PatientRegistrationComponent } from "./patient-registration/patient-registration.component";
import { RefillPrescriptionService } from './services/refill-prescription.service';
import { PatientRegistrationService } from './services/patient-registration.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    RefillPrescriptionsComponent,
    PatientRegistrationComponent,
    PhoneMaskDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: 'patient-registration', component: PatientRegistrationComponent },
      { path: 'refill-prescriptions', component: RefillPrescriptionsComponent }
    ]),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    RefillPrescriptionService,
    PatientRegistrationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
