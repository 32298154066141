import { Component, HostListener, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import { RefillPrescriptionService } from "../services/refill-prescription.service";
import { RefillPrescription } from "../models/refillPrescription";


@Component({
  selector: 'app-refill-prescriptions',
  templateUrl: './refill-prescriptions.component.html',
  styleUrls: ['../assets/css/common.css']
})
export class RefillPrescriptionsComponent implements OnInit {
  @ViewChild(BsDatepickerDirective, { static: false }) birthDatePicker: BsDatepickerDirective;
  @ViewChild(BsDatepickerDirective, { static: false }) doseDatePicker: BsDatepickerDirective;
  @ViewChild(BsDatepickerDirective, { static: false }) deliveryDatePicker: BsDatepickerDirective;
  refillFormGroup: FormGroup;
  submitted = false;
  isWeekend = false;
  isHoliday = false;
  holidays: readonly string[] = ["1/1", "1/15", "3/31", "5/12", "5/27", "7/4", "9/2", "11/28", "12/25", "12/31"];
  errorMessage: any;
  refillPrescription: RefillPrescription;
  totalCharactersLeftAnySupplies: number;
  totalCharactersLeftQuestions: number;
  showForm: boolean = true;
  showSuccess: boolean = false;
  showFail: boolean = false;

  minBirthDate: Date;
  maxBirthDate: Date;
  minDeliveryDate: Date;
  maxDeliveryDate: Date;
  minDoseDueDate: Date;
  maxDoseDueDate: Date;

  constructor(private refillPrescriptionService: RefillPrescriptionService,
    private formBuilder: FormBuilder) {
    this.minDoseDueDate = new Date();
    this.maxDoseDueDate = new Date();
    this.minDoseDueDate.setDate(this.minDoseDueDate.getDate() - 45);
    this.maxDoseDueDate.setDate(this.maxDoseDueDate.getDate() + 45);

    this.minDeliveryDate = new Date();
    this.maxDeliveryDate = new Date();
    this.minDeliveryDate.setDate(this.minDeliveryDate.getDate() + 1);
    this.maxDeliveryDate.setDate(this.maxDeliveryDate.getDate() + 30);

    this.minBirthDate = new Date();
    this.maxBirthDate = new Date();
    this.minBirthDate.setDate(this.minBirthDate.getDate() - 36545);
    this.maxBirthDate.setDate(this.maxBirthDate.getDate() - 1);

  }

  @HostListener('window:scroll')
  onScrollEvent() {
    this.birthDatePicker.hide();
    this.doseDatePicker.hide();
    this.deliveryDatePicker.hide();
  }

  ngOnInit() {
    this.totalCharactersLeftAnySupplies = 250;
    this.totalCharactersLeftQuestions = 250;

    this.refillFormGroup = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        prescriptionNumber: ['', Validators.required],
        birthDate: ['', Validators.required],
        daytimePhoneNumber: ['', Validators.required],
        daytimePhoneNumberExt: [''],
        anySuppliesNeeded: ['', Validators.required],
        haveYouMissedAnyDoses: ['', Validators.required],
        whenIsYourNextDoseDue: ['', Validators.required],
        streetAddress: ['', Validators.required],
        streetAddress2: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zipcode: ['', Validators.required],
        country: [''],
        contactPhoneNumber: ['', Validators.required],
        contactPhoneNumberExt: [''],
        preferredDeliveryDate: ['', Validators.required],
        chargeCreditCardAuthorization: ['', Validators.required],
        questionsRegardingMedications: ['', Validators.required]
      }
    );
  }

  get f() { return this.refillFormGroup.controls; }

  isRequiredFieldEmpty(field: string) {
    if (this.refillFormGroup.controls[field].value.replace(/\s/g, '') === '') {
      this.refillFormGroup.controls[field].setErrors({ 'invalid': true });
    }
  }

  isWeekendValidation() {
    if (this.refillFormGroup.controls['preferredDeliveryDate'].value !== '') {
      if (this.refillFormGroup.controls['preferredDeliveryDate'].value.getDay() === 0 ||
        this.refillFormGroup.controls['preferredDeliveryDate'].value.getDay() === 1 ||
        this.refillFormGroup.controls['preferredDeliveryDate'].value.getDay() === 6) {
        this.isWeekend = true;
        this.refillFormGroup.controls['preferredDeliveryDate'].setErrors({ 'invalid': true });
      } else {
        this.isWeekend = false;
      }
    }
  }

  isHolidayValidation() {
    if (this.refillFormGroup.controls['preferredDeliveryDate'].value !== '') {

      var selectedDate = this.refillFormGroup.get('preferredDeliveryDate').value.getMonth() + 1 + '/' + this.refillFormGroup.get('preferredDeliveryDate').value.getDate();

      if (this.holidays.includes(selectedDate)) {
        this.isHoliday = true;
        this.refillFormGroup.controls['preferredDeliveryDate'].setErrors({ 'invalid': true });
      } else {
        this.isHoliday = false;
      }
    }
  }

  validate(field: string) {

    this.lettersAndSomeSpecialCharsOnlyFields('firstName');
    this.isRequiredFieldEmpty('firstName');
    this.lettersAndSomeSpecialCharsOnlyFields('lastName');
    this.isRequiredFieldEmpty('lastName');
    this.alphaNumericFields('prescriptionNumber');
    this.isRequiredFieldEmpty('prescriptionNumber');
    this.isRequiredFieldEmpty('daytimePhoneNumber');
    this.numbersOnlyFields('daytimePhoneNumberExt');
    this.alphaNumericAndSomeSpecialCharsOnlyFields('anySuppliesNeeded');
    this.isRequiredFieldEmpty('anySuppliesNeeded');
    this.alphaNumericFields('streetAddress');
    this.isRequiredFieldEmpty('streetAddress');
    this.alphaNumericFields('streetAddress2');
    this.lettersOnlyFields('city');
    this.isRequiredFieldEmpty('city');
    this.numbersOnlyFields('zipcode');
    this.isRequiredFieldEmpty('zipcode');
    this.isRequiredFieldEmpty('contactPhoneNumber');
    this.numbersOnlyFields('contactPhoneNumberExt');
    this.alphaNumericFieldsWithQuestionMark('questionsRegardingMedications');
    this.isRequiredFieldEmpty('questionsRegardingMedications');
    this.isWeekendValidation();
    this.isHolidayValidation();

    if (this.refillFormGroup.get(field).value.toString().replace(/\s/g, '') === '' && this.submitted) {
      return 'required';
    }
    return '';
  }

  submit() {
    window.scroll(0, 0);

    this.submitted = true;

    if (!this.refillFormGroup.valid) {
      return;
    }

    let refillPrescription: RefillPrescription = {
      firstName: this.refillFormGroup.get('firstName').value,
      lastName: this.refillFormGroup.get('lastName').value,
      prescriptionNumber: this.refillFormGroup.get('prescriptionNumber').value,
      birthDate: this.refillFormGroup.get('birthDate').value.getMonth() + 1 + '/' + this.refillFormGroup.get('birthDate').value.getDate() + '/' + this.refillFormGroup.get('birthDate').value.getFullYear(),
      daytimePhoneNumber: this.refillFormGroup.get('daytimePhoneNumber').value,
      daytimePhoneNumberExt: this.refillFormGroup.get('daytimePhoneNumberExt').value,
      anySuppliesNeeded: this.refillFormGroup.get('anySuppliesNeeded').value,
      haveYouMissedAnyDoses: this.refillFormGroup.get('haveYouMissedAnyDoses').value,
      whenIsYourNextDoseDue: this.refillFormGroup.get('whenIsYourNextDoseDue').value.getMonth() + 1 + '/' + this.refillFormGroup.get('whenIsYourNextDoseDue').value.getDate() + '/' + this.refillFormGroup.get('whenIsYourNextDoseDue').value.getFullYear(),
      shippingAddress: this.addressBuilder(),
      contactPhoneNumber: this.refillFormGroup.get('contactPhoneNumber').value,
      contactPhoneNumberExt: this.refillFormGroup.get('contactPhoneNumberExt').value,

      preferredDeliveryDate: this.refillFormGroup.get('preferredDeliveryDate').value.getMonth() + 1 + '/' + this.refillFormGroup.get('preferredDeliveryDate').value.getDate() + '/' + this.refillFormGroup.get('preferredDeliveryDate').value.getFullYear(),

      chargeCreditCardAuthorization: this.refillFormGroup.get('chargeCreditCardAuthorization').value,
      questionsRegardingMedications: this.refillFormGroup.get('questionsRegardingMedications').value,
      authorization: this.refillFormGroup.get('chargeCreditCardAuthorization').value
    };

    this.refillPrescriptionService.submitRefillPrescription(refillPrescription)
      .subscribe((data) => {
        if (JSON.stringify(data).includes('200')) {
          this.showForm = false;
          this.showSuccess = true;
          this.showFail = false;
        } else {
          this.showForm = false;
          this.showSuccess = false;
          this.showFail = true;
        }
        //alert(JSON.stringify(data));
      });
  }

  charactersCounter(controlName: string) {
    switch (controlName) {
      case 'anySuppliesNeeded':
        this.totalCharactersLeftAnySupplies = 250 - this.refillFormGroup.get(controlName).value.length;
        break;
      case 'questionsRegardingMedications':
        this.totalCharactersLeftQuestions = 250 - this.refillFormGroup.get(controlName).value.length;
        break;
      default:
        break;
    }

  }

  addressBuilder() {
    let address: string[] = [];

    address.push(
      this.refillFormGroup.get('streetAddress').value,
      this.refillFormGroup.get('streetAddress2').value,
      this.refillFormGroup.get('city').value,
      this.refillFormGroup.get('state').value,
      ',',
      this.refillFormGroup.get('zipcode').value,
      ',',
      this.refillFormGroup.get('country').value
    );

    return address.join(' ');
  }


  lettersAndSomeSpecialCharsOnlyFields(controlName: string) {
    this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(/[^a-zA-Z \-.'&]/g, ""));
  }

  alphaNumericAndSomeSpecialCharsOnlyFields(controlName: string) {
    this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(/[^a-zA-Z0-9 \-.'&]/g, ""));
  }

  alphaNumericFieldsWithQuestionMark(controlName: string) {
    this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(/[^a-zA-Z0-9 \-.'&?]/g, ""));
  }

  lettersOnlyFields(controlName: string) {
    this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(/[^a-zA-Z ]/g, ""));
  }

  numbersOnlyFields(controlName: string) {
    this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(/[^0-9]/g, ""));
  }

  alphaNumericFields(controlName: string) {
    this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(/[^a-zA-Z0-9 ]/g, ""));
  }

  phoneFormater(controlName: string) {
    //if (this.refillFormGroup.get(controlName).value.includes('(')) {
    //  this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace('(', ''));
    //}
    //if (this.refillFormGroup.get(controlName).value.includes(')')) {
    //  this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(')', ''));
    //}
    //if (this.refillFormGroup.get(controlName).value.includes('-')) {
    //  this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace('-', ''));
    //}
    //if (this.refillFormGroup.get(controlName).value.includes(' ')) {
    //  this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(' ', ''));
    //}
    //this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value.replace(/\D/g, ''));
    this.refillFormGroup.get(controlName).setValue(this.refillFormGroup.get(controlName).value
      .replace(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/, ""));
  }

}
