import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { PatientRegistration } from "../models/patientRegistration";
import { PatientRegistrationService } from '../services/patient-registration.service';

@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['../assets/css/common.css']
})
export class PatientRegistrationComponent implements OnInit {
  @ViewChild(BsDatepickerDirective, { static: false }) birthDatePicker: BsDatepickerDirective;
  genericSubstitutionPreference: string;
  cellPhoneOptIn: string;
  totalCharactersLeftMedicationAllergies: number;
  totalCharactersLeftEnvironmentalFoodAllergies: number;
  totalCharactersLeftHealthCondition: number;
  totalCharactersLeftgenericSubstitutionBrandPreference: number;
  registrationFormGroup: FormGroup;
  minBirthDate: Date;
  maxBirthDate: Date;
  submitted: boolean = false;
  isHomePhoneValid: boolean = true;
  isEmergencyPhoneValid: boolean = true;
  isPhysicianPhone: boolean = true;
  isCellPhoneValid: boolean = true;
  isWorkPhoneValid: boolean = true;
  isPharmacyHelpDeskPhoneValid: boolean = true;
  isSecondaryPharmacyHelpDeskPhoneValid: boolean = true;
  showForm: boolean = true;
  showSuccess: boolean = false;
  showFail: boolean = false;
  patientRegistration: PatientRegistration;

  constructor(private formBuilder: FormBuilder, private patientRegistrationService: PatientRegistrationService) {
    this.minBirthDate = new Date();
    this.maxBirthDate = new Date();
    this.minBirthDate.setDate(this.minBirthDate.getDate() - 36545);
    this.maxBirthDate.setDate(this.maxBirthDate.getDate() - 1);
  }

  ngOnInit() {
    this.totalCharactersLeftMedicationAllergies = 200;
    this.totalCharactersLeftEnvironmentalFoodAllergies = 200;
    this.totalCharactersLeftHealthCondition = 200;
    this.totalCharactersLeftgenericSubstitutionBrandPreference = 250;

    this.genericSubstitutionPreference = 'Please dispense the generic medication if available';
    this.cellPhoneOptIn = 'No';

    this.registrationFormGroup = this.formBuilder.group(
      {
        genericSubstitutionBrandPreference: [''],
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: ['', Validators.required],
        gender: [''],

        mailingStreetAddress: ['', Validators.required],
        mailingStreetAddress2: [''],
        mailingCity: ['', Validators.required],
        mailingState: ['', Validators.required],
        mailingZipcode: ['', Validators.required],
        mailingCountry: [''],

        shippingStreetAddress: ['', Validators.required],
        shippingStreetAddress2: [''],
        shippingCity: ['', Validators.required],
        shippingState: ['', Validators.required],
        shippingZipcode: ['', Validators.required],
        shippingCountry: [''],

        birthDate: ['', Validators.required],
        homePhone: ['', Validators.minLength(14)],
        cellPhone: ['', [Validators.required, Validators.minLength(14)]],
        cellPhoneOptIn: [''],
        workPhone: ['', Validators.minLength(14)],
        workPhoneExt: [''],
        eMail: ['', Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)],
        preferredMethodOfCommunication: [''],

        physicianFirstName: ['', Validators.required],
        physicianLastName: ['', Validators.required],
        physicianPhone: ['', [Validators.required, Validators.minLength(14)]],

        insuranceName: ['', Validators.required],
        rxBin: [''],
        rxPcnNumber: [''],
        pharmacyHelpDeskPhone: ['', Validators.minLength(14)],
        memberId: ['', Validators.required],
        relationshipToCardholder: [''],
        rxGroup: ['', Validators.required],

        secondaryInsuranceName: [''],
        secondaryRxBin: [''],
        secondaryRxPcnNumber: [''],
        secondaryPharmacyHelpDeskPhone: [''],
        secondaryMemberId: [''],
        secondaryRelationshipToCardholder: [''],
        secondaryRxGroup: [''],

        medicationAllergies: [''],
        environmentalFoodAllergies: [''],
        healthCondition: [''],

        emergencyContactName: ['', Validators.required],
        emergencyRelationship: ['', Validators.required],
        emergencyPhone: ['', [Validators.required, Validators.minLength(14)]],
        emergencyPhoneExt: [''],
      });
  }

  onItemChange(value) {
    this.genericSubstitutionPreference = value;
  }

  onCheckBoxClick(checkboxControl) {
    if (checkboxControl.checked) {
      this.cellPhoneOptIn = 'Yes';
    } else {
      this.cellPhoneOptIn = 'No';
    }
  }

  charactersCounter(controlName: string) {
    switch (controlName) {
      case 'genericSubstitutionBrandPreference':
        this.totalCharactersLeftgenericSubstitutionBrandPreference = 250 - this.registrationFormGroup.get(controlName).value.length;
        break;
      case 'medicationAllergies':
        this.totalCharactersLeftMedicationAllergies = 200 - this.registrationFormGroup.get(controlName).value.length;
        break;
      case 'environmentalFoodAllergies':
        this.totalCharactersLeftEnvironmentalFoodAllergies = 200 - this.registrationFormGroup.get(controlName).value.length;
        break;
      case 'healthCondition':
        this.totalCharactersLeftHealthCondition = 200 - this.registrationFormGroup.get(controlName).value.length;
        break;
      default:
        break;
    }
  }

  get f() { return this.registrationFormGroup.controls; }

  isRequiredFieldEmpty(field: string) {
    if (this.registrationFormGroup.controls[field].value.replace(/\s/g, '') === '') {
      this.registrationFormGroup.controls[field].setErrors({ 'invalid': true });
    }
  }

  validate(field: string) {
    this.alphaNumericFieldsWithQuestionMark('genericSubstitutionBrandPreference');
    this.lettersAndSomeSpecialCharsOnlyFields('firstName');
    this.isRequiredFieldEmpty('firstName');
    this.lettersAndSomeSpecialCharsOnlyFields('middleName');
    this.lettersAndSomeSpecialCharsOnlyFields('lastName');
    this.isRequiredFieldEmpty('lastName');

    this.alphaNumericFields('mailingStreetAddress');
    this.isRequiredFieldEmpty('mailingStreetAddress');
    this.alphaNumericFields('mailingStreetAddress2');
    this.lettersOnlyFields('mailingCity');
    this.isRequiredFieldEmpty('mailingCity');
    this.numbersOnlyFields('mailingZipcode');
    this.isRequiredFieldEmpty('mailingZipcode');

    this.alphaNumericFields('shippingStreetAddress');
    this.isRequiredFieldEmpty('shippingStreetAddress');
    this.alphaNumericFields('shippingStreetAddress2');
    this.lettersOnlyFields('shippingCity');
    this.isRequiredFieldEmpty('shippingCity');
    this.numbersOnlyFields('shippingZipcode');
    this.isRequiredFieldEmpty('shippingZipcode');

    this.isRequiredFieldEmpty('cellPhone');

    this.numbersOnlyFields('workPhoneExt');

    this.lettersAndSomeSpecialCharsOnlyFields('physicianFirstName');
    this.isRequiredFieldEmpty('physicianFirstName');
    this.lettersAndSomeSpecialCharsOnlyFields('physicianLastName');
    this.isRequiredFieldEmpty('physicianLastName');
    this.isRequiredFieldEmpty('physicianPhone');

    this.alphaNumericFields('insuranceName');
    this.isRequiredFieldEmpty('insuranceName');
    this.numbersOnlyFields('rxBin');
    this.alphaNumericFields('rxPcnNumber');
    this.alphaNumericFields('memberId');
    this.isRequiredFieldEmpty('memberId');
    this.lettersAndSomeSpecialCharsOnlyFields('relationshipToCardholder');
    this.alphaNumericFields('rxGroup');
    this.isRequiredFieldEmpty('rxGroup');

    this.alphaNumericFields('secondaryInsuranceName');
    this.numbersOnlyFields('secondaryRxBin');
    this.alphaNumericFields('secondaryRxPcnNumber');
    this.alphaNumericFields('secondaryMemberId');
    this.lettersAndSomeSpecialCharsOnlyFields('secondaryRelationshipToCardholder');
    this.alphaNumericFields('secondaryRxGroup');

    this.alphaNumericAndSomeSpecialCharsOnlyFields('medicationAllergies');
    this.alphaNumericAndSomeSpecialCharsOnlyFields('environmentalFoodAllergies');
    this.alphaNumericAndSomeSpecialCharsOnlyFields('healthCondition');

    this.lettersAndSomeSpecialCharsOnlyFields('emergencyContactName');
    this.isRequiredFieldEmpty('emergencyContactName');
    this.lettersAndSomeSpecialCharsOnlyFields('emergencyRelationship');
    this.isRequiredFieldEmpty('emergencyRelationship');
    this.isRequiredFieldEmpty('emergencyPhone');

    if (!this.registrationFormGroup.get(field).valid && this.submitted) {
      return 'required';
    }
  }

  @HostListener('window:scroll')
  onScrollEvent() {
    if (this.birthDatePicker !== undefined) { this.birthDatePicker.hide(); }
  }


  addressBuilder(prefix: string) {
    let address: string[] = [];

    address.push(
      this.registrationFormGroup.get(prefix + 'StreetAddress').value,
      this.registrationFormGroup.get(prefix + 'StreetAddress2').value,
      this.registrationFormGroup.get(prefix + 'City').value,
      this.registrationFormGroup.get(prefix + 'State').value,
      ',',
      this.registrationFormGroup.get(prefix + 'Zipcode').value,
      ',',
      this.registrationFormGroup.get(prefix + 'Country').value
    );

    return address.join(' ');
  }

  validatePhoneLength() {
    if (this.registrationFormGroup.controls['homePhone'].value.length !== 14 && this.registrationFormGroup.controls['homePhone'].value.length > 1) {
      this.registrationFormGroup.controls['homePhone'].setErrors({ 'invalid': true });
    }
    if (this.registrationFormGroup.controls['cellPhone'].value.length !== 14 && this.registrationFormGroup.controls['cellPhone'].value.length > 1) {
      this.registrationFormGroup.controls['cellPhone'].setErrors({ 'invalid': true });
    }
    if (this.registrationFormGroup.controls['workPhone'].value.length !== 14 && this.registrationFormGroup.controls['workPhone'].value.length > 1) {
      this.registrationFormGroup.controls['workPhone'].setErrors({ 'invalid': true });
    }
    if (this.registrationFormGroup.controls['physicianPhone'].value.length !== 14 && this.registrationFormGroup.controls['physicianPhone'].value.length > 1) {
      this.registrationFormGroup.controls['physicianPhone'].setErrors({ 'invalid': true });
    }
    if (this.registrationFormGroup.controls['pharmacyHelpDeskPhone'].value.length !== 14 && this.registrationFormGroup.controls['pharmacyHelpDeskPhone'].value.length > 1) {
      this.registrationFormGroup.controls['pharmacyHelpDeskPhone'].setErrors({ 'invalid': true });
    }
    if (this.registrationFormGroup.controls['secondaryPharmacyHelpDeskPhone'].value.length !== 14 && this.registrationFormGroup.controls['secondaryPharmacyHelpDeskPhone'].value.length > 1) {
      this.registrationFormGroup.controls['secondaryPharmacyHelpDeskPhone'].setErrors({ 'invalid': true });
    }
    if (this.registrationFormGroup.controls['emergencyPhone'].value.length !== 14 && this.registrationFormGroup.controls['emergencyPhone'].value.length > 1) {
      this.registrationFormGroup.controls['emergencyPhone'].setErrors({ 'invalid': true });
    }
  }

  submit() {

    window.scroll(0, 0);

    this.submitted = true;

    this.validatePhoneLength();

    if (!this.registrationFormGroup.valid) {
      return;
    }

    let patientRegistration: PatientRegistration =
    {
      genericSubstitutionPreference: this.genericSubstitutionPreference,
      genericSubstitutionBrandPreference: this.registrationFormGroup.get('genericSubstitutionBrandPreference').value,
      firstName: this.registrationFormGroup.get('firstName').value,
      middleName: this.registrationFormGroup.get('middleName').value,
      lastName: this.registrationFormGroup.get('lastName').value,
      gender: this.registrationFormGroup.get('gender').value,
      mailingAddress: this.addressBuilder('mailing'),
      shippingAddress: this.addressBuilder('shipping'),
      birthDate: this.registrationFormGroup.get('birthDate').value.getMonth() + 1 + '/' + this.registrationFormGroup.get('birthDate').value.getDate() + '/' + this.registrationFormGroup.get('birthDate').value.getFullYear(),
      homePhone: this.registrationFormGroup.get('homePhone').value,
      cellPhone: this.registrationFormGroup.get('cellPhone').value,
      cellPhoneOptIn: this.cellPhoneOptIn,
      workPhone: this.registrationFormGroup.get('workPhone').value,
      workPhoneExt: this.registrationFormGroup.get('workPhoneExt').value,
      eMail: this.registrationFormGroup.get('eMail').value,
      preferredMethodOfCommunication: this.registrationFormGroup.get('preferredMethodOfCommunication').value,
      physicianFirstName: this.registrationFormGroup.get('physicianFirstName').value,
      physicianLastName: this.registrationFormGroup.get('physicianLastName').value,
      physicianPhone: this.registrationFormGroup.get('physicianPhone').value,
      insuranceName: this.registrationFormGroup.get('insuranceName').value,
      rxBin: this.registrationFormGroup.get('rxBin').value,
      rxPcnNumber: this.registrationFormGroup.get('rxPcnNumber').value,
      pharmacyHelpDeskPhone: this.registrationFormGroup.get('pharmacyHelpDeskPhone').value,
      memberId: this.registrationFormGroup.get('memberId').value,
      relationshipToCardholder: this.registrationFormGroup.get('relationshipToCardholder').value,
      rxGroup: this.registrationFormGroup.get('rxGroup').value,

      secondaryInsuranceName: this.registrationFormGroup.get('secondaryInsuranceName').value,
      secondaryRxBin: this.registrationFormGroup.get('secondaryRxBin').value,
      secondaryRxPcnNumber: this.registrationFormGroup.get('secondaryRxPcnNumber').value,
      secondaryPharmacyHelpDeskPhone: this.registrationFormGroup.get('secondaryPharmacyHelpDeskPhone').value,
      secondaryMemberId: this.registrationFormGroup.get('secondaryMemberId').value,
      secondaryRelationshipToCardholder: this.registrationFormGroup.get('secondaryRelationshipToCardholder').value,
      secondaryRxGroup: this.registrationFormGroup.get('secondaryRxGroup').value,

      medicationAllergies: this.registrationFormGroup.get('medicationAllergies').value,
      environmentalFoodAllergies: this.registrationFormGroup.get('environmentalFoodAllergies').value,
      healthCondition: this.registrationFormGroup.get('healthCondition').value,
      emergencyContactName: this.registrationFormGroup.get('emergencyContactName').value,
      emergencyRelationship: this.registrationFormGroup.get('emergencyRelationship').value,
      emergencyPhone: this.registrationFormGroup.get('emergencyPhone').value,
      emergencyPhoneExt: this.registrationFormGroup.get('emergencyPhoneExt').value,
    };

    this.patientRegistrationService.submitRefillPrescription(patientRegistration)
      .subscribe((data) => {
        if (JSON.stringify(data).includes('200')) {
          this.showForm = false;
          this.showSuccess = true;
          this.showFail = false;
        } else {
          this.showForm = false;
          this.showSuccess = false;
          this.showFail = true;
        }
      });
  }

  alphaNumericAndSomeSpecialCharsOnlyFields(controlName: string) {
    this.registrationFormGroup.get(controlName).setValue(this.registrationFormGroup.get(controlName).value.replace(/[^a-zA-Z0-9 \-.'&]/g, ""));
  }

  alphaNumericFieldsWithQuestionMark(controlName: string) {
    this.registrationFormGroup.get(controlName).setValue(this.registrationFormGroup.get(controlName).value.replace(/[^a-zA-Z0-9 \-.'&?]/g, ""));
  }

  alphaNumericAndPeriodOnlyFields(controlName: string) {
    this.registrationFormGroup.get(controlName).setValue(this.registrationFormGroup.get(controlName).value.replace(/[^a-zA-Z0-9 .]/g, ""));
  }

  lettersAndSomeSpecialCharsOnlyFields(controlName: string) {
    this.registrationFormGroup.get(controlName).setValue(this.registrationFormGroup.get(controlName).value.replace(/[^a-zA-Z \-.'& ]/g, ""));
  }

  lettersOnlyFields(controlName: string) {
    this.registrationFormGroup.get(controlName).setValue(this.registrationFormGroup.get(controlName).value.replace(/[^a-zA-Z ]/g, ""));
  }

  numbersOnlyFields(controlName: string) {
    this.registrationFormGroup.get(controlName).setValue(this.registrationFormGroup.get(controlName).value.replace(/[^0-9 ]/g, ""));
  }

  alphaNumericFields(controlName: string) {
    this.registrationFormGroup.get(controlName).setValue(this.registrationFormGroup.get(controlName).value.replace(/[^a-zA-Z0-9 ]/g, ""));
  }

  formatPhoneNumber(event, controlName: string) {
    var phoneNumberString = this.registrationFormGroup.get(controlName).value;
    var phoneNumber = '';

    var key = event.keyCode;
    if (key === 8) {
      if (phoneNumberString.length === 2) {
        this.registrationFormGroup.get(controlName).setValue('');
        return;
      }
    }

    this.registrationFormGroup.get(controlName).setValue(this.phoneNumberFormatter(phoneNumberString, controlName));

    if (phoneNumberString.length > 14) {
      phoneNumber = phoneNumberString.slice(0, 14);
      this.registrationFormGroup.get(controlName).setValue(this.phoneNumberFormatter(phoneNumber, controlName));
    }
  }

  phoneNumberFormatter(phoneNumberString: string, controlName: string) {
    if (phoneNumberString.length > 0 && phoneNumberString.replace(/[^0-9 ]/g, "").replace(' ',"").length === 10) {
      this.validPhoneNumber(controlName, true);
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
      }
    }
    else if (phoneNumberString.length === 0) {
      this.validPhoneNumber(controlName, true);
    }
    else if (phoneNumberString.length > 0 && phoneNumberString.length < 14) {
      this.validPhoneNumber(controlName, false);
    }
    return phoneNumberString;
  }
  validPhoneNumber(controlName: string, isValid: boolean) {
    switch (controlName.toLocaleLowerCase()) {
      case 'homephone':
        this.isHomePhoneValid = isValid;
        break;
      case 'emergencyphone':
        this.isEmergencyPhoneValid = isValid;
        break;
      case 'cellphone':
        this.isCellPhoneValid = isValid;
        break;
      case 'physicianphone':
        this.isPhysicianPhone = isValid;
        break;
      case 'workphone':
        this.isWorkPhoneValid = isValid;
        break;
      case 'pharmacyhelpdeskphone':
        this.isPharmacyHelpDeskPhoneValid = isValid;
        break;
      case 'secondarypharmacyhelpdeskphone':
        this.isSecondaryPharmacyHelpDeskPhoneValid = isValid;
        break;
    }

  }
}
